import React, {useEffect, useRef, useState} from "react";
import "./UpdateProfile.css";
import {useNavigate} from "react-router-dom";

const UpdateProfile: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // const idRef = useRef<HTMLInputElement>(null);
    let id = '';
    const designationRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const bankRef = useRef<HTMLInputElement>(null);
    const bankAccNoRef = useRef<HTMLInputElement>(null);
    const ifscRef = useRef<HTMLInputElement>(null);
    const dobRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const employeeData = sessionStorage.getItem("myData");
    const employeeName = employeeData ? JSON.parse(employeeData).name : "Admin";


    useEffect(() => {
        const empId = sessionStorage.getItem("EMPID");
        if (empId) {
            id = empId;
        }
        console.log(id);
    }, []);

    const signUpHandler = async (e: React.FormEvent) => {
        e.preventDefault();

        // const id = idRef.current?.value;
        const designation = designationRef.current?.value;
        const phone = phoneRef.current?.value;
        const bank = bankRef.current?.value;
        const bankAccNo = bankAccNoRef.current?.value;
        const ifsc = ifscRef.current?.value;
        const dob = dobRef.current?.value;

        // Validate required fields
        if (!designation || !phone || !bank || !bankAccNo || !ifsc || !dob) {
            setErrorMessage("All fields are required.");
            return;
        }

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await fetch(`${API_URL}/Employee/Register/EMP_REG_SECOND`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,
                    designation,
                    phone,
                    bank,
                    bankAccNo,
                    ifsc,
                    dob,
                }),
            });

            if (!response.ok) {
                throw new Error("Registration failed. Please try again.");
            }

            const data = await response.json();
            if (data.error === "0")
                navigate("/Dashboard", {state: {myData: {id}}});
            else
                setErrorMessage("Registration failed. Please check your details.");

        } catch (error: any) {
            setErrorMessage(error.message || "Something went wrong. Please try again.");
        }
    };

    return (
        <div className="sign-up-two">
            <form onSubmit={signUpHandler}>
                <h1>Update Details</h1>
                <p style={{fontWeight: 'normal'}}>Employee Name: {employeeName}</p> {/* Display employee name */}
                <label>
                    <input ref={designationRef} type="text" placeholder="Designation" required/>
                </label>
                <label>
                    <input ref={phoneRef} type="tel" placeholder="Phone" required/>
                </label>
                <label>
                    <input ref={bankRef} type="text" placeholder="Bank" required/>
                </label>
                <label>
                    <input ref={bankAccNoRef} type="text" placeholder="Bank Account Number" required/>
                </label>
                <label>
                    <input ref={ifscRef} type="text" placeholder="IFSC Code" required/>
                </label>
                <label>
                    <input ref={dobRef} type="date" required/>
                </label>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <button type="submit">Submit Details</button>
            </form>
        </div>
    );
};

export default UpdateProfile;
