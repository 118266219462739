import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from "../UI/loadingSpinner/LoadingSpinner";
import Pagination from "../Pagination/Pagination"; // Assuming you have a Pagination component
import './ViewAttendance.css';
import { Icon } from "@iconify/react";
import { useSidebar } from "../../store/sidebarContext";
import ThemeContext from "../../store/themeContext";

interface Attendance {
    [key: number]: number;
    id: string;
    sno: number;
    year: number;
    month: number;
}

const AttendanceTable: React.FC = () => {
    const { employeeId } = useParams<{ employeeId: string }>();
    const location = useLocation();
    const { theme, toggleTheme } = useContext(ThemeContext);
    const [attendanceData, setAttendanceData] = useState<Attendance[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(0);
    const { isOpen } = useSidebar();
    const API_URL = process.env.REACT_APP_API_URL;

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    useEffect(() => {
        if (location.state && location.state.attendanceData) {
            setAttendanceData(location.state.attendanceData);
            setLoading(false);
        }
    }, [location.state]);

    const handlePageChange = (page: number) => {
        setCurrentMonthIndex(page - 1);
    };

    const handleCellClick = (day: number | null) => {
        if (day === null) return;

        const empId = sessionStorage.getItem("EMPID");
        if (!empId) {
            alert("Employee ID is not available in sessionStorage");
            return;
        }

        const { month, year } = attendanceData[currentMonthIndex];
        const selectedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

        axios.post(`${API_URL}/Employee/Atdc/getAttendanceData`, { id: empId, date: selectedDate })
            .then(response => {
                if (response.data.error === "0") {
                    alert(`Attendance data for ${selectedDate}: ${JSON.stringify(response.data.msg)}`);
                } else {
                    alert(`Error: ${response.data.msg}`);
                }
            })
            .catch(error => {
                alert("Something went wrong while fetching attendance data.");
            });
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!attendanceData.length) {
        return <h4 style={{ color: 'red', textAlign: 'center', justifyContent: 'center' }}>
            No attendance data available.
        </h4>;
    }

    const currentMonthData = attendanceData[currentMonthIndex];
    const { month, year } = currentMonthData;
    const monthName = monthNames[month - 1];
    const daysInMonth = new Date(year, month, 0).getDate();
    const firstDayOfMonth = new Date(year, month - 1, 1).getDay();

    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const calendarDays: (number | null)[] = [
        ...Array.from({ length: firstDayOfMonth }, () => null),
        ...daysArray,
    ];

    const totalWeeks = Math.ceil(calendarDays.length / 7);

    const totalPresent = daysArray.reduce(
        (count, day) => currentMonthData[day] === 1 ? count + 1 : count,
        0
    );
    const totalAbsent = daysArray.reduce(
        (count, day) => currentMonthData[day] === 0 ? count + 1 : count,
        0
    );
    const totalLeaves = daysArray.reduce(
        (count, day) => currentMonthData[day] === -1 ? count + 1 : count,
        0
    );

    return (
        <div className={`container ${theme}`}>
            <button onClick={toggleTheme} style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}></button>
            <div className={`summary-container ${isOpen ? "with-sidebar" : "without-sidebar"}`}>
                <h2 className="table-title">Attendance for {monthName} {year}</h2>
                <div className="data">
                    <div className="card" style={{background: "linear-gradient(145deg,#64b5f6, #2196f3)"}}>
                        <div className="title">Total Present</div>
                        <div className="value">{totalPresent}</div>
                        <Icon icon="ic:twotone-work" className="icon"/>
                    </div>

                    <div className="card" style={{background: "linear-gradient(145deg, #8c6cef,#8d3fed)"}}>
                        <div className="title">Total Absent</div>
                        <div className="value">{totalAbsent}</div>
                        <Icon icon="subway:error" className="icon"/>
                    </div>

                    <div className="card" style={{background: "linear-gradient(145deg, #59ed38, #08a80f)"}}>
                        <div className="title">Total Leaves</div>
                        <div className="value">{totalLeaves}</div>
                        <Icon icon="pepicons-pencil:leave-circle-filled" className="icon"/>
                    </div>
                </div>
            </div>
            <p style={{color: theme === 'dark' ? 'white' : 'black' }}>Employee ID: {employeeId}</p>
            <br />
            <table className="data-table">
                <thead>
                <tr>
                    <th>Sunday</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                </tr>
                </thead>
                <tbody>
                {Array.from({ length: totalWeeks }).map((_, weekIndex) => (
                    <tr key={weekIndex}>
                        {calendarDays.slice(weekIndex * 7, weekIndex * 7 + 7).map((day, dayIndex) => (
                            <td
                                key={dayIndex}
                                style={{
                                    verticalAlign: 'bottom',
                                    position: 'relative',
                                    cursor: day ? 'pointer' : 'default',
                                }}
                                onClick={() => handleCellClick(day)}
                            >
                                <div>
                                    {day !== null && currentMonthData[day] !== undefined ? (
                                        currentMonthData[day] === 1 ? (
                                            <Icon icon="entypo:check" style={{ width: '25px', color: 'green' }} />
                                        ) : (
                                            <Icon icon="noto:cross-mark" style={{ color: 'red' }} />
                                        )
                                    ) : ''}
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '5px',
                                        fontSize: '0.8em',
                                        color: theme === 'dark' ? 'white' : '#888',
                                    }}
                                >
                                    {day || ''}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentMonthIndex + 1}
                totalPages={attendanceData.length}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default AttendanceTable;
