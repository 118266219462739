import React, { useContext, useEffect } from "react";
import LoginContext from "../store/loginContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthLayout = () => {
  const location = useLocation();
  const loginCtx = useContext(LoginContext);

  // Redirect if not logged in
  if (!loginCtx.isLogin) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <Outlet />;
};

export default AuthLayout;
