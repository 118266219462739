import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {SidebarProvider} from "./store/sidebarContext";
import {ThemeContextProvider} from "./store/themeContext";
import {LoginContextProvider} from "./store/loginContext";

ReactDOM.render(
    <LoginContextProvider>
        <ThemeContextProvider>
            <SidebarProvider>
                <App/>
            </SidebarProvider>
        </ThemeContextProvider>
    </LoginContextProvider>,
    document.getElementById("root")
);
