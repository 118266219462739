import React, { useEffect, useState, useCallback } from "react";
import { useSessionStorage } from "usehooks-ts";

type TContext = {
  isLogin: boolean;
  userType: string | null;
  employeeName: string | null;
  toggleLogin: () => void;
  setUserType: (type: string) => void;
  setEmployeeName: (name: string) => void;
};

const LoginContext = React.createContext<TContext>({
  isLogin: false,
  userType: null,
  employeeName: null,
  toggleLogin: () => {},
  setUserType: () => {},
  setEmployeeName: () => {},
});

export const LoginContextProvider: React.FC = (props) => {
  const [isLogin, setIsLogin] = useSessionStorage("isLogin", false);
  const [userType, setUserType] = useState<string | null>(null);
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  // Sync `isLogin` with `sessionStorage`
  const syncLoginState = useCallback(() => {
    const token = sessionStorage.getItem("token");
    setIsLogin(!!token); // Update based on token existence
  }, [setIsLogin]);

  // Toggle login state
  const toggleLogin = () => {
    if (sessionStorage.getItem("token")) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  // Effect to initialize and listen for storage changes
  useEffect(() => {
    syncLoginState(); // Sync on mount

    const handleStorageChange = () => {
      syncLoginState();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [syncLoginState]);

  const loginValue: TContext = {
    isLogin,
    userType,
    employeeName,
    toggleLogin,
    setUserType,
    setEmployeeName,
  };

  return (
      <LoginContext.Provider value={loginValue}>
        {props.children}
      </LoginContext.Provider>
  );
};

export default LoginContext;
