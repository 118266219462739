import React, { useContext, useState } from "react";
import "./Upload.css";
import ThemeContext from "../../store/themeContext";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";

const Upload: React.FC = () => {
    const [files, setFiles] = useState<{ [key: string]: File | null }>({
        photo: null,
        aadhar: null,
        bank_passbook: null,
        marksheet_10: null,
        marksheet_12: null,
        grad_marksheet: null,
        pg_marksheet: null,
        pan_id: null,
    });
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = event.target;
        const file = files ? files[0] : null;
        setFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true); // Set loading to true when submission starts
        setErrorMessage(null);

        const formData = new FormData();

        for (const key in files) {
            if (files[key]) {
                formData.append(key, files[key]!); // Non-null assertion
            }
        }

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await fetch(`${API_URL}/Employee/Register/upload`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Upload failed. Please try again.");
            }

            const data = await response.json();
            if (data.error === "0") {
                navigate("/Dashboard");
            } else {
                setErrorMessage("Upload failed. Please check your details.");
            }
        } catch (error: any) {
            setErrorMessage(error.message || "Something went wrong. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`profile-page ${theme}`}>
            <div className="main-content">
                <div className="sign-up-two">
                    {loading ? (
                        <LoadingSpinner /> // Display LoadingSpinner while loading
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <h1>Upload Documents</h1>
                            {errorMessage && <p className="error">{errorMessage}</p>}
                            {[
                                { label: "Profile Photo", name: "photo" },
                                { label: "Aadhar Card", name: "aadhar" },
                                { label: "Bank Passbook", name: "bank_passbook" },
                                { label: "10th Marksheet", name: "marksheet_10" },
                                { label: "12th Marksheet", name: "marksheet_12" },
                                { label: "Graduate Marksheet", name: "grad_marksheet" },
                                { label: "Postgraduate Marksheet", name: "pg_marksheet" },
                                { label: "PAN ID", name: "pan_id" },
                            ].map((field) => (
                                <label key={field.name}>
                                    {field.label}
                                    <input
                                        type="file"
                                        name={field.name}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </label>
                            ))}
                            <button type="submit">Upload Documents</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Upload;
