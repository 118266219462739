import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import ThemeContext from "../../store/themeContext";
import "./AssignTask.css";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";

interface Employee {
    id: string;
    name: string;
}

const AssignTask: React.FC = () => {
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null); // Error state remains as a string
    const {theme, toggleTheme} = useContext(ThemeContext);

    const [selectedEmployee, setSelectedEmployee] = useState<string>("");
    const [selectedViewers, setSelectedViewers] = useState<string[]>([]);
    const [task, setTask] = useState<string>("");
    const [doa, setDoa] = useState<string>("");
    const [deadline, setDeadline] = useState<string>("");
    const [documentFile, setDocumentFile] = useState<File | null>(null);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${API_URL}/Admin/Fetch/EMP_HIRED`, {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("token"),
                    },
                });
                setEmployees(response.data.msg);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch employees.");
                setLoading(false);
            }
        };

        fetchEmployees();
    }, [API_URL]);

    const handleAssignTask = async (e: React.FormEvent) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("id", selectedEmployee);
        formData.append("viewers", selectedViewers.join(","));
        formData.append("task", task);
        formData.append("doa", doa);
        formData.append("deadline", deadline);

        if (documentFile) {
            formData.append("File", documentFile);
        }

        try {
            const response = await axios.post(`${API_URL}/Admin/Routes/AssignTask`, formData, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.error === "0") {
                alert("Task assigned successfully!");
                setSelectedEmployee("");
                setSelectedViewers([]);
                setTask("");
                setDoa("");
                setDeadline("");
                setDocumentFile(null);
            } else {
                setError(response.data.msg || "An unexpected error occurred.");
            }
        } catch (err: any) {
            // Handle and parse error object
            const errorMessage =
                err.response?.data?.msg ||
                err.message ||
                "Failed to assign task.";
            setError(errorMessage);
        }
    };

    const handleViewerSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const viewerId = e.target.value;
        if (viewerId && !selectedViewers.includes(viewerId)) {
            setSelectedViewers([...selectedViewers, viewerId]);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setDocumentFile(e.target.files[0]);
        }
    };

    return (
        <div className='container'>
            <div className={`assign-task ${theme}`}>
                <button onClick={toggleTheme}
                        style={{background: "transparent", border: "none", cursor: "none"}}></button>
                <h1>TASK ASSIGNMENT</h1>
                {loading ? (
                    <LoadingSpinner/>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : (
                    <form onSubmit={handleAssignTask}>
                        <label>
                            Assign To:
                            <select value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)}
                                    required>
                                <option value="">Select an employee</option>
                                {employees.map((employee) => (
                                    <option key={employee.id} value={employee.id}>
                                        {employee.name} - {employee.id}
                                    </option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Add Viewers:
                            <select value="" onChange={handleViewerSelection}>
                                <option value="">Select a viewer</option>
                                {employees
                                    .filter(
                                        (emp) =>
                                            emp.id !== selectedEmployee && !selectedViewers.includes(emp.id)
                                    )
                                    .map((employee) => (
                                        <option key={employee.id} value={employee.id}>
                                            {employee.name} - {employee.id}
                                        </option>
                                    ))}
                            </select>
                        </label>

                        {selectedViewers.length > 0 && (
                            <div className="selected-viewers">
                                <h3>Selected Viewers:</h3>
                                <ul>
                                    {selectedViewers.map((viewerId) => {
                                        const viewer = employees.find((emp) => emp.id === viewerId);
                                        return viewer ? (
                                            <li key={viewerId}>
                                                {viewer.name} - {viewer.id}
                                            </li>
                                        ) : null;
                                    })}
                                </ul>
                            </div>
                        )}

                        <div className="date-inputs">
                            <label>
                                Date of Assignment (DOA):
                                <input type="date" value={doa} onChange={(e) => setDoa(e.target.value)} required/>
                            </label>
                            <label>
                                Deadline:
                                <input type="date" value={deadline} onChange={(e) => setDeadline(e.target.value)}
                                       required/>
                            </label>
                        </div>

                        <label>
                            Task:
                            <textarea value={task} onChange={(e) => setTask(e.target.value)} required/>
                        </label>

                        <label>
                            Supporting Document:
                            <input type="file" onChange={handleFileChange}/>
                        </label>
                        <button type="submit">Assign Task</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default AssignTask;
