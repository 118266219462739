import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import ThemeContext from "../../store/themeContext";
import "./LeaveMgmt.css";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";
import Pagination from "../../components/Pagination/Pagination";
import {Icon} from "@iconify/react";

const LeaveMgmt: React.FC = () => {
    const [id, setId] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [dol, setDol] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [leaves, setLeaves] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const {theme} = useContext(ThemeContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const userType = sessionStorage.getItem("userType");

    useEffect(() => {
        const empId = sessionStorage.getItem("EMPID");
        if (empId) {
            setId(empId);
        }

        if (userType === "2") {
            fetchAllLeaves();
        }
    }, [userType]);

    const fetchAllLeaves = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${API_URL}/Admin/Fetch/GetAllLeaves`, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
            });

            if (response.data.error === "0") {
                const formattedLeaves = response.data.msg.map((leave: any) => ({
                    ...leave,
                    dol: new Date(leave.dol).toLocaleDateString(),
                    status:
                        leave.status === 0
                            ? "Pending"
                            : leave.status === 1
                                ? "Approved"
                                : leave.status === 2
                                    ? "Rejected"
                                    : "Unknown",
                }));
                setLeaves(formattedLeaves);
            } else {
                setError(response.data.msg || "Failed to fetch leave data.");
            }
        } catch (err: any) {
            const errorMessage =
                err.response?.data?.msg || err.message || "Error fetching leave data.";
            setError(typeof errorMessage === "string" ? errorMessage : JSON.stringify(errorMessage));
        } finally {
            setLoading(false);
        }
    };

    const handleApplyLeave = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                `${API_URL}/Employee/Routes/ApplyLeave`,
                {id, reason, dol},
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("token"),
                    },
                }
            );

            if (response.data.error === "0") {
                alert("Leave applied successfully!");
                setReason("");
                setDol("");
            } else {
                setError(response.data.msg || "An unexpected error occurred.");
            }
        } catch (err: any) {
            const errorMessage =
                err.response?.data?.msg || err.message || "Failed to apply for leave.";
            setError(typeof errorMessage === "string" ? errorMessage : JSON.stringify(errorMessage));
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const itemsPerPage = 10;
    const totalPages = Math.ceil(leaves.length / itemsPerPage);
    const currentItems = leaves.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const ChangeStatus = async (id: number, status: number) => {
        setLoading(true); // Show loading spinner during status change
        try {
            const response = await axios.post(`${API_URL}/Admin/Routes/setLeaveStatus`,
                {id, status},
                {
                    headers: {
                        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchAllLeaves();
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`container ${theme}`}>
            {loading ? (
                <LoadingSpinner/>
            ) : error ? (
                <p className="error">{error}</p>
            ) : userType === "2" ? (
                <>
                    <div className="table-title blurred-title">Leave Requests</div>
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Reason</th>
                            <th>Date of Leave</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.map((leave) => (
                            <tr key={leave.id}>
                                <td>{leave.id}</td>
                                <td>{leave.reason}</td>
                                <td>{leave.dol}</td>
                                <td>
                                        <span
                                            style={{
                                                color:
                                                    leave.status === "Approved"
                                                        ? "green"
                                                        : leave.status === "Rejected"
                                                            ? "red"
                                                            : "black",
                                            }}
                                        >
                                            {leave.status}
                                        </span>
                                </td>
                                <td>
                                    <button onClick={() => ChangeStatus(leave.id, 1)} title="Approve Leave">
                                        <Icon icon="dashicons:yes" width="27px" height="27px" style={{border: 'none', cursor: 'pointer', background: 'transparent',marginRight: '25px',minWidth: 'auto',maxWidth: '30px', color: 'green'}} />
                                    </button>
                                    <button onClick={() => ChangeStatus(leave.id, -1)} title="Reject Leave">
                                        <Icon icon="dashicons:no" width="27px" height="27px" style={{border: 'none', cursor: 'pointer', background: 'transparent',minWidth: 'auto',maxWidth: '30px', color: 'red'}}/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <div className={`assign-task ${theme}`}>
                    <h1>LEAVE MANAGEMENT</h1>
                    <form onSubmit={handleApplyLeave}>
                        <label>
                            Employee ID:
                            <input type="text" value={id} disabled/>
                        </label>

                        <label>
                            Reason for Leave:
                            <textarea
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            Date of Leave:
                            <input
                                type="date"
                                value={dol}
                                onChange={(e) => setDol(e.target.value)}
                                required
                            />
                        </label>

                        <button type="submit">Apply Leave</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default LeaveMgmt;
